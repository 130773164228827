<template>
  <div class="inline-iframe-container">
    <iframe
      class="video"
      width="100%"
      height="460"
      :src="videoSource"
      frameborder="0"
      :style="maxHeight ? `max-height: ${maxHeight}px` : ''"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>
  </div>
  <div v-if="showPopup" ref="videoPopup" class="video-popup">
    <div class="popup">
      <iframe
        class="video"
        width="100%"
        height="460"
        :src="videoSource"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <svg
        class="close"
        data-v-56dc7368=""
        xmlns="http://www.w3.org/2000/svg"
        width="1.5em"
        height="1.5em"
        viewBox="0 0 20 20"
        @click="() => togglePopup()"
      >
        <path
          data-v-56dc7368=""
          fill="currentColor"
          d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07m1.41-1.41A8 8 0 1 0 15.66 4.34A8 8 0 0 0 4.34 15.66m9.9-8.49L11.41 10l2.83 2.83l-1.41 1.41L10 11.41l-2.83 2.83l-1.41-1.41L8.59 10L5.76 7.17l1.41-1.41L10 8.59l2.83-2.83z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'VideoPopup',

  mixins: [ComponentMixin],

  props: {
    videoSource: {
      type: String,
      required: true,
    },
    videoTitle: {
      type: String,
      required: false,
    },

    maxHeight: {
      required: false,
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      showPopup: false,
    };
  },

  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup;
      const sectionServicesContent = document.querySelectorAll('.section-services-content');
      if (this.showPopup && sectionServicesContent) {
        sectionServicesContent.forEach((section: any) => {
          section.style.zIndex = 'unset';
        });
      } else {
        sectionServicesContent.forEach((section: any) => {
          section.style.zIndex = '1';
        });
      }
    },

    openVideo() {
      this.togglePopup();
      const videoCoverDiv = this.$refs.videoCover as HTMLDivElement;
      const iframeSrc = videoCoverDiv.querySelector('iframe')?.getAttribute('src');

      const iframeVideo = this.$refs.iframeVideo as HTMLIFrameElement;

      if (iframeSrc && iframeVideo) {
        iframeVideo.setAttribute('src', iframeSrc);
      }
    },
  },
});
</script>

<style scoped>
.inline-iframe-container {
  width: 100%;
  cursor: pointer;
}

.inline-iframe-container iframe {
  height: 388px;
}

.video-cover {
  width: 100%;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.video-cover::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.popup {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.video-popup {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
}

.video-popup video,
.video-popup iframe {
  position: relative;
  max-width: 900px;
  outline: none;
}

.video-popup .close {
  position: absolute;
  top: 30px;
  right: 30px;
  max-width: 35px;
  cursor: pointer;
  filter: invert(1);
}

.video-popup {
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 991px) {
  .video-popup video,
  .video-popup iframe {
    max-width: 90%;
  }
}
</style>
